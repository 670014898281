// themes.js
export const lightTheme = {
  background: '#ffffff',
  text: '#333333',
  lastMessage: 'rgba(0, 0, 0, 0.6)',
  headerBackground: '#f4f4f4',
  headerText: '#333333',
  chatBackground: '#f9f9f9',
  messageBackgroundSender: '#d1e7ff',
  messageBackgroundReceiver: '#ffffff',
  messageTextSender: '#333333',
  messageTextReceiver: '#333333',
  inputBackground: '#f4f4f4',
  inputText: '#333333',
  buttonBackground: '#4caf50',
  buttonText: '#ffffff',
  scrollbarTrack: '#f1f1f1',
  scrollbarThumb: '#888888',
  toggleBackground: '#d0e2ff',
  toggleCircle: '#ffffff',
  toggleIconColor: '#333333',
  toggleLabelColor: '#888888',
};
  
export const darkTheme = {
  background: '#1e1e1e',
  text: '#ffffff',
  lastMessage: 'rgba(255, 255, 255, 0.6)',
  headerBackground: '#2a2f32',
  headerText: '#ffffff',
  chatBackground: '#0b141a',
  messageBackgroundSender: '#1d72b8',
  messageBackgroundReceiver: '#262d31',
  messageTextSender: '#ffffff',
  messageTextReceiver: '#ffffff',
  inputBackground: '#333333',
  inputText: '#ffffff',
  buttonBackground: '#1d72b8',
  buttonText: '#ffffff',
  scrollbarTrack: '#2a2f32',
  scrollbarThumb: '#888888',
  toggleBackground: '#1e1e1e',
  toggleCircle: '#ffffff',
  toggleIconColor: '#ffffff',
  toggleLabelColor: '#888888',
};
