import React, { useState, useContext } from 'react';
import axios from 'axios';
import { SearchContainer, Input, Dropdown, DropdownItem } from '../styles';
import { ThemeContext } from '../ThemeContext';

const SearchUser = ({ onSelectUser, currentUser }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const { theme } = useContext(ThemeContext);

  const searchUsers = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      try {
        const response = await axios.get(`/api/users/search/?q=${value}`);
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    } else {
      setResults([]);
    }
  };

  const handleSelectUser = (user) => {
    onSelectUser(user);
    setQuery('');
    setResults([]);
  };

  return (
    <SearchContainer>
      <Input
        type="text"
        value={query}
        onChange={searchUsers}
        placeholder="Search users or Start a new chat"
        theme={theme}
      />
      {results.length > 0 && (
        <Dropdown theme={theme}>
          {results.map(user => (
            <DropdownItem key={user.id} onClick={() => handleSelectUser(user)} theme={theme}>
              {user.username === currentUser ? `${user.username} (You)` : user.username}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </SearchContainer>
  );
};

export default SearchUser;
