import React, { useState, useContext } from 'react';
import axios from 'axios';
import { SearchContainer, InputGroup, Dropdown, DropdownItem, AddGroupIcon } from '../styles';
import { ThemeContext } from '../ThemeContext';
const SearchGroup = ({ onSelectGroup, currentUser,onCreateGroupClick }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const { theme } = useContext(ThemeContext);
  const searchGroups = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      try {
        const response = await axios.get(`/api/groups/search/?user=${currentUser}&q=${value}`);
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    } else {
      setResults([]);
    }
  };

  const handleSelectGroup = (group) => {
    onSelectGroup(group, true);
    setQuery('');
    setResults([]);
  };

  return (
    <SearchContainer>
      <InputGroup
        type="text"
        value={query}
        onChange={searchGroups}
        placeholder="Search a Group"
        theme={theme}
      />
      
    <AddGroupIcon src="/static/services-logo/add-group-1.png" alt='Add Group Icon' onClick={onCreateGroupClick}></AddGroupIcon>
   
      {results.length > 0 && (
        <Dropdown theme={theme}>
          {results.map(group => (
            <DropdownItem key={group.id} onClick={() => handleSelectGroup(group)} theme={theme}>
            {group.name}
          </DropdownItem>
          ))}
        </Dropdown>
      )}
      
    </SearchContainer>
    
  );
};

export default SearchGroup;
