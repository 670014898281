// GroupCreationModal.js
import React from 'react';
import { ModalOverlay, ModalContent, CloseButton } from '../styles';
import GroupCreation from './GroupCreation';

const GroupCreationModal = ({ currentUser, onSelectChat, onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>X</CloseButton>
        <GroupCreation currentUser={currentUser} onSelectChat={onSelectChat} onClose={onClose} />
      </ModalContent>
    </ModalOverlay>
  );
};

export default GroupCreationModal;
