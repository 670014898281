import React, { useState } from 'react';
import { Input, Button } from '../styles';

const UserPrompt = ({ onSubmit }) => {
  const [username, setUsername] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(username);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Enter your username:
        <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <Button type="submit">Submit</Button>
    </form>
  );
};

export default UserPrompt;
