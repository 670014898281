// App.js
import React, { useState, useEffect } from 'react';
import UserPrompt from './components/UserPrompt';
import ChatList from './components/ChatList';
import SearchUser from './components/SearchUser';
import SearchGroup from './components/SearchGroup';
import ChatWindow from './components/ChatWindow';
// import LandingPage from './components/LandingPage';
import CustomThemeProvider from './ThemeContext';
import { Container, Sidebar, MainContent, Header, ChatListContainer, UserProfile, Avatar, UserName, ToggleButton, BackButton } from './styles';
import { getRandomColor } from './utils';
import GroupCreationModal from './components/GroupCreationModal';
// import ThemeSwitcher from './ThemeSwitcher';
import { GlobalStyle } from './globalStyles';

const Chat = () => {
  const [currentUser, setCurrentUser] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [isGroupChat, setIsGroupChat] = useState(false);
  const [viewType, setViewType] = useState('Chats');
  const [isGroupCreationOpen, setIsGroupCreationOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      try {
        const decoded = JSON.parse(atob(token));
        setCurrentUser(decoded.username);

        // Clear the URL query parameter
        const newUrl = window.location.protocol + '//' + window.location.host;
        window.history.pushState({ path: newUrl }, '', newUrl);
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
  }, []);
  
  if (!currentUser) {
    return <div>Access Denied.<button onClick={() => {
      window.location.href = 'https://tick.officeofananyabirla.com/landing';
    }}>Home</button></div>;
  }

  // if (!currentUser) {
  //   return <UserPrompt onSubmit={setCurrentUser} />;
  // }

  const handleSelectChat = (chat, isGroup) => {
    setSelectedChat(chat);
    setIsGroupChat(isGroup);
  };

  const toggleGroupCreation = () => {
    setIsGroupCreationOpen(!isGroupCreationOpen);
  };

  const goToHomePage = () => {
    window.location.href = 'https://tick.officeofananyabirla.com/landing';
  };

  return (
    <Container>
      <Sidebar>
        <Header>
          <BackButton onClick={goToHomePage} />
          <h2>{viewType}</h2>
          <ToggleButton onClick={() => setViewType(viewType === 'Chats' ? 'Groups' : 'Chats')}>
            {viewType === 'Chats' ? 'Groups' : 'Chats'}
          </ToggleButton>
        </Header>
        {viewType === 'Chats' ? <SearchUser onSelectUser={handleSelectChat} currentUser={currentUser} /> : (
          <SearchGroup onSelectGroup={handleSelectChat} currentUser={currentUser} onCreateGroupClick={toggleGroupCreation} />
        )}
        <ChatListContainer>
          <ChatList onSelectChat={handleSelectChat} currentUser={currentUser} type={viewType.toLowerCase()} />
        </ChatListContainer>
        <UserProfile>
          <Avatar bgColor={getRandomColor(currentUser)}>
            {currentUser.charAt(0).toUpperCase()}{currentUser.charAt(1).toUpperCase()}
          </Avatar>
          <UserName>{currentUser}</UserName>
        </UserProfile>
      </Sidebar>
      <MainContent>
        {selectedChat && <ChatWindow user={selectedChat} currentUser={currentUser} isGroup={isGroupChat} />}
      </MainContent>
      {isGroupCreationOpen && (
        <GroupCreationModal
          currentUser={currentUser}
          onSelectChat={handleSelectChat}
          onClose={toggleGroupCreation}
        />
      )}
    </Container>
  );
};

const App = () => {
  return (
    <CustomThemeProvider>
       <GlobalStyle />
      <Chat />
    </CustomThemeProvider>
  );
};

export default App;
