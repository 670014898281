import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ChatListContainer, ChatItem, ChatItemContent, ChatItemText, UserAvatar, LastMessage, Timestamp, PinIcon } from '../styles';
import { getRandomColor } from '../utils';
import { ThemeContext } from '../ThemeContext';

const ChatList = ({ onSelectChat, currentUser, type }) => {
  const [chats, setChats] = useState([]);
  const [groups, setGroups] = useState([]);
  const { theme } = useContext(ThemeContext);

  const fetchRecentChats = () => {
    axios.get('/api/recent-chats/', { params: { user: currentUser } }).then(response => {
      setChats(response.data);
    });
  };

  const fetchGroups = () => {
    axios.get('/api/groups/', { params: { user: currentUser } }).then(response => {
      setGroups(response.data);
    });
  };

  useEffect(() => {
    fetchRecentChats();
    fetchGroups();
  }, [currentUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRecentChats();
      fetchGroups();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSelectChat = (chat, isGroup) => {
    onSelectChat(chat, isGroup);
  };

  const formatTimestamp = (timestamp) => {

    if (!timestamp) {
      return new Date().toLocaleDateString('en-US');
    }

    const messageDate = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    if (messageDate.toDateString() === today.toDateString()) {
      return messageDate.toLocaleTimeString('en-US', options);
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString('en-US');
    }
  };

  const pinnedGroup = groups.find(group => group.name === 'OAB');
  const otherGroups = groups.filter(group => group.name !== 'OAB');

  return (
    <ChatListContainer>
      {type === 'chats' && chats.map(chat => (
        <ChatItem key={chat.id} onClick={() => handleSelectChat(chat, false)} theme={theme}>
          <ChatItemContent>
            <UserAvatar bgColor={getRandomColor(chat.username)}>
              {chat.username.charAt(0).toUpperCase()}{chat.username.charAt(1).toUpperCase()}
            </UserAvatar>
            <ChatItemText>
              <div>{chat.username === currentUser ? `${chat.username} (You)` : chat.username}</div>
              <LastMessage>{chat.last_message}</LastMessage>
            </ChatItemText>
          </ChatItemContent>
          <Timestamp>{formatTimestamp(chat.timestamp)}</Timestamp>
        </ChatItem>
      ))}
      {type === 'groups' && pinnedGroup && (
        <ChatItem key={pinnedGroup.id} onClick={() => handleSelectChat(pinnedGroup, true)} theme={theme}>
          <ChatItemContent>
            <UserAvatar bgColor={getRandomColor(pinnedGroup.name)}>
              {pinnedGroup.name.charAt(0).toUpperCase()}{pinnedGroup.name.charAt(1).toUpperCase()}
            </UserAvatar>
            <ChatItemText>
              <div>{pinnedGroup.name} <PinIcon></PinIcon></div>
              <LastMessage>{pinnedGroup.last_message}</LastMessage>
            </ChatItemText>
          </ChatItemContent>
          <Timestamp>{formatTimestamp(pinnedGroup.last_message_timestamp)}</Timestamp>
        </ChatItem>
      )}
      {type === 'groups' && otherGroups.map(group => (
        <ChatItem key={group.id} onClick={() => handleSelectChat(group, true)} theme={theme}>
          <ChatItemContent>
            <UserAvatar bgColor={getRandomColor(group.name)}>
              {group.name.charAt(0).toUpperCase()}{group.name.charAt(1).toUpperCase()}
            </UserAvatar>
            <ChatItemText>
              <div>{group.name}</div>
              <LastMessage>{group.last_message}</LastMessage>
            </ChatItemText>
          </ChatItemContent>
          <Timestamp>{formatTimestamp(group.last_message_timestamp)}</Timestamp>
        </ChatItem>
      ))}
    </ChatListContainer>
  );
};

export default ChatList;