const colors = [
  '#4caf50', // Green
  '#2196f3', // Blue
  '#9c27b0', // Purple
  '#3f51b5', // Indigo
  '#009688', // Teal
];

let colorMapping = {};

export const getRandomColor = (username) => {
  if (!colorMapping[username]) {
    const color = colors[Object.keys(colorMapping).length % colors.length];
    colorMapping[username] = color;
  }
  return colorMapping[username];
};
