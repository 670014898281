import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: ${({ theme }) => theme.background};
`;

export const Sidebar = styled.div`
  width: 300px;
  background: ${({ theme }) => theme.headerBackground};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.chatBackground};
`;

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    color: ${({ theme }) => theme.headerText};
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.headerBackground};
`;

export const ToggleButton = styled.button`
  background: ${({ active, theme }) => (active ? theme.buttonBackground : theme.headerBackground)};
  color: ${({ active, theme }) => (active ? theme.buttonText : theme.headerText)};
  border: 1px solid ${({ theme }) => theme.buttonBackground};
  font-size:0.9em;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonText};
  }
`;

export const BackButton = styled.div`
  width: 36px;
  height: 36px;
  background: url('/static/services-logo/icons8-home-128.svg') no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

export const ChatListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
`;

export const ChatItem = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #414e68;
  &:hover {
    background: #414e68;
  }
`;

export const ChatItemContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 10px;
`;

export const ChatItemText = styled.div`
  flex: 1;
`;

export const LastMessage = styled.div`
  width:150px;
  font-size: 0.9em;
  color: ${({ theme }) => theme.lastMessage};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Timestamp = styled.div`
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.6);
  margin-left: auto;
`;

export const PinIcon = styled.span`
  margin-left: 5px;
  color: gold;
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  color: black;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

export const DeleteIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url('/static/services-logo/trash-dark.svg') no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DeleteIconTray = styled.div`
  display: none;
  position: absolute;
  background: #262d31;
  border-radius: 8px;
  padding: 5px;
  top: -28px;
  right: 0;
  z-index: 1;
  animation: ${slideDown} 0.3s ease;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.chatBackground};
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #414e68;
  background-color: ${({ theme }) => theme.headerBackground};
  color: ${({ theme }) => theme.headerText};
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 10px;
`;

export const UserName = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: ${({ theme }) => theme.headerText};
`;

export const MessageContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MessageItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-break: break-word; 
`;

export const MessageItemSender = styled(MessageItem)`
  background: ${({ theme }) => theme.messageBackgroundSender};
  color: ${({ theme }) => theme.messageTextSender};
  align-self: flex-end;
  border-top-right-radius: 0;
  &:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: ${({ theme }) => theme.messageBackgroundSender};
    border-right: 0;
    border-top: 0;
    margin-top: -10px;
    margin-left: -10px;
  }
  &:hover ${DeleteIconTray} {
    display: block;
  }
`;

export const MessageItemReceiver = styled(MessageItem)`
  background: ${({ theme }) => theme.messageBackgroundReceiver};
  color: ${({ theme }) => theme.messageTextReceiver};
  align-self: flex-start;
  border-top-left-radius: 0;
  &:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: ${({ theme }) => theme.messageBackgroundReceiver};
    border-left: 0;
    border-top: 0;
    margin-top: -10px;
    margin-right: -10px;
  }
`;

export const TimestampInMessage = styled.div`
  align-self: flex-end;
  font-size: 0.8em;
  color: ${({ theme }) => theme.text};
  margin-top: 5px;
`;

export const DateDivider = styled.div`
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.text};
  font-size: 0.9em;
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 10px;
  background: ${({ theme }) => theme.inputBackground};
  border-top: 1px solid #ccc;
`;

export const TextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  word-break: break-word; 
  min-height: 20px;
  max-height: 100px;
  overflow-y: auto;
  resize: none;
`;

export const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  background: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  width: 95%;
`;

export const InputGroup = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  background: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  width: 75%;
`;

export const AddGroupIcon = styled.img`
  vertical-align: middle;
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

export const GroupToggleButton = styled.button`
  position: relative;
  background: ${({ active, theme }) => (active ? theme.buttonBackground : theme.headerBackground)};
  color: ${({ active, theme }) => (active ? theme.buttonText : theme.headerText)};
  border: 1px solid ${({ theme }) => theme.buttonBackground};
  
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonText};
  }
`;

export const Button = styled.button`
  max-height:40px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: darken(${({ theme }) => theme.buttonBackground}, 10%);
  }
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  border-top: 1px solid #414e68;
  background-color: ${({ theme }) => theme.headerBackground};
  color: ${({ theme }) => theme.text};
  margin-top: auto;
`;

export const GroupCreationContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 8px;
`;

export const GroupCreationHeader = styled.h3`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const GroupInput = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
`;

export const GroupButton = styled.button`
  margin-top: 5px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: darken(${({ theme }) => theme.buttonBackground}, 10%);
  }
`;

export const UserListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const UserItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #414e68;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.chatBackground};
  height: 65%;
  width: 25%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: darken(${({ theme }) => theme.buttonBackground}, 10%);
  }
`;

export const AttachmentIcon = styled.div`
  margin: 8px 8px 0px 0px;
  width: 24px;
  height: 24px;
  background: url('/static/services-logo/paperclip-solid.svg') no-repeat center center;
  background-size: contain;
  cursor: pointer;
  transform: rotate(-45deg);
`;

export const FileInput = styled.input`
  display: none;
`;

export const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ImageContainer = styled.div`
  display: inline-block;
  margin: 5px;
  cursor: pointer;
`;

export const ImageThumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

export const ImagePopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ImagePopup = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  border-radius: 8px;
`;

export const DownloadButton = styled.a`
  position: absolute;
  top: 20px;
  right: 60px;
  background: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  color: #000;
`;

export const ImageCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
`;

export const GroupManageButton = styled.button`
  background: ${({ active, theme }) => (active ? theme.buttonBackground : theme.headerBackground)};
  color: ${({ active, theme }) => (active ? theme.buttonText : theme.headerText)};
  border: 1px solid ${({ theme }) => theme.buttonBackground};
  font-size:0.9em;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonText};
  }
`;

export const RemoveMemberButton = styled.button`
  max-height:40px;
  padding: 10px 20px;
  background: #bd1c1c;
  color: ${({ theme }) => theme.buttonText};
  margin-left: auto;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: darken(${({ theme }) => theme.buttonBackground}, 10%);
  }
`;

export const GroupModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupModalContent = styled.div`
  background: ${({ theme }) => theme.chatBackground};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const GroupModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
`;

export const GroupModalBody = styled.div`
  padding: 20px 0;
`;

export const GroupModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  padding-top: 10px;
`;

export const GroupModalCloseButton = styled.button`
  position: relative;
  top: -20px;
  right: 10px;
  background: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: darken(${({ theme }) => theme.buttonBackground}, 10%);
  }
`;
