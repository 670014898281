import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  GroupCreationContainer,
  GroupCreationHeader,
  GroupButton,
  UserListContainer,
  UserItem,
  GroupInput
} from '../styles';

const GroupCreation = ({ currentUser, onSelectChat, onClose }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users/');
        const filteredUsers = response.data.filter(user => user.username !== currentUser);
        setUsers(filteredUsers);
        const currentUserResponse = response.data.find(user => user.username === currentUser);
        setCurrentUserId(currentUserResponse.id);
        setSelectedUsers([currentUserResponse]); // Include currentUser by default
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [currentUser]);

  const handleUserSelect = (user) => {
    setSelectedUsers(prevSelectedUsers => {
      if (prevSelectedUsers.includes(user)) {
        return prevSelectedUsers.filter(u => u !== user);
      } else {
        return [...prevSelectedUsers, user];
      }
    });
  };

  const handleCreateGroup = async () => {
    const memberIds = selectedUsers.map(user => user.id);
    try {
      const response = await axios.post('/api/groups/create/', { name: groupName, members: memberIds });
      onSelectChat(response.data, true);
      onClose();
    } catch (error) {
      console.error('Error creating group:', error.response.data);
    }
  };

  const filteredUsers = users.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleNext = () => {
    setStep(2);
  };

  return (
    <GroupCreationContainer>
      {step === 1 && (
        <>
          <GroupCreationHeader>Select Users</GroupCreationHeader>
          <GroupInput 
            type="text" 
            placeholder="Search users..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
          <UserListContainer>
            {filteredUsers.map(user => (
              <UserItem key={user.id} onClick={() => handleUserSelect(user)} style={{ background: selectedUsers.includes(user) ? '#1d72b8' : '#262d31' }}>
                {user.username}
              </UserItem>
            ))}
          </UserListContainer>
          <GroupButton onClick={handleNext} disabled={selectedUsers.length === 0}>
            Next
          </GroupButton>
        </>
      )}
      {step === 2 && (
        <>
          <GroupCreationHeader>Enter Group Name</GroupCreationHeader>
          <GroupInput
            type="text"
            placeholder="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <GroupButton onClick={handleCreateGroup} disabled={!groupName}>
            Create Group
          </GroupButton>
        </>
      )}
    </GroupCreationContainer>
  );
};

export default GroupCreation;
