import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  ChatContainer,
  MessageContainer,
  MessageWrapper,
  MessageItemSender,
  MessageItemReceiver,
  TimestampInMessage,
  DateDivider,
  InputContainer,
  Button,
  ChatHeader,
  Avatar,
  UserName,
  TextArea,
  AttachmentIcon,
  FileInput,
  ImageContainer,
  ImageThumbnail,
  ImagePopup,
  ImagePopupOverlay,
  ImageCloseButton,
  DownloadButton,
  GroupManageButton,
  GroupModalOverlay,
  GroupModalContent,
  GroupModalHeader,
  GroupModalBody,
  GroupModalFooter,
  GroupModalCloseButton,
  UserListContainer,
  UserItem,
  DeleteIcon,
  DeleteIconTray,
  RemoveMemberButton,
} from '../styles';
import { getRandomColor } from '../utils';

const sanitizeRoomName = (name) => name.replace(/[^a-zA-Z0-9]/g, '_');

const ChatWindow = ({ user, currentUser, isGroup }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [popupImage, setPopupImage] = useState(null);
  const [showGroupMembers, setShowGroupMembers] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef(null);
  const fileInputRef = useRef(null);

  const roomName = isGroup ? `group_${user.id}` : [currentUser, user.username].sort().join('_');
  const sanitizedRoomName = sanitizeRoomName(roomName);

  useEffect(() => {
    setNewMessage('');
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '20px'; // Reset height for 1 row
    }
  }, [user, isGroup]);

  useEffect(() => {
    console.log(`Connecting to WebSocket room: ${sanitizedRoomName}`);
    let protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    socketRef.current = new WebSocket(`${protocol}://${window.location.hostname}:8000/ws/chat/${sanitizedRoomName}/`);

    socketRef.current.onopen = () => {
      console.log('WebSocket connection opened.');
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socketRef.current.onmessage = (e) => {
      console.log('WebSocket message received:', e.data);
      const message = JSON.parse(e.data);
      const recipient = message.recipient?.username || message.recipient || null;

      if (isGroup && message.group === user.name) {
        setMessages((prevMessages) => {
          if (!prevMessages.some(m => m.timestamp === message.timestamp && m.content === message.content)) {
            console.log('Adding new group message:', message);
            return [...prevMessages, message];
          }
          return prevMessages;
        });
      } else if (!isGroup && ((message.sender.username === user.username && recipient === currentUser) || 
                  (message.sender.username === currentUser && recipient === user.username))) {
        setMessages((prevMessages) => {
          if (!prevMessages.some(m => m.timestamp === message.timestamp && m.content === message.content)) {
            console.log('Adding new message:', message);
            return [...prevMessages, message];
          }
          return prevMessages;
        });
      } else {
        console.log(`Received message for a different chat: ${message}`);
      }
    };

    return () => {
      console.log('Closing WebSocket connection');
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [currentUser, user.username, roomName, isGroup]);

  const sendMessage = () => {
    if (!newMessage.trim()) {
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const message = isGroup
        ? {
            sender: currentUser,
            group: user.name,
            content: newMessage,
            timestamp: new Date().toISOString(),
          }
        : {
            sender: currentUser,
            recipient: user.username,
            content: newMessage,
            timestamp: new Date().toISOString(),
          };
      console.log('Sending message:', message);
      socketRef.current.send(JSON.stringify(message));
      setNewMessage('');
      if (textAreaRef.current) {
        textAreaRef.current.style.height = '20px'; // Default height for 1 row
      }
    } else {
      console.error('WebSocket is not open.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleInput = (e) => {
    setNewMessage(e.target.value);
    if (e.target.value === ''){
      textAreaRef.current.style.height = '20px';
    }
    else if (e.target.scrollHeight > 40) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight, 100)}px`;
    } else {
      textAreaRef.current.style.height = '20px';
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('sender', currentUser);
      if (isGroup) {
        formData.append('group', user.id);
      } else {
        formData.append('recipient', user.username);
      }
      formData.append('file', file);
      
      try {
        await axios.post('/api/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // No need to set messages here, rely on WebSocket to update messages
        fileInputRef.current.value = ''; // Clear the input after upload
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await axios.delete(`/api/messages/${messageId}/delete`);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const downloadFile = async (filename) => {
    try {
      const response = await fetch(`/api/download/${filename}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };
  

  useEffect(() => {
    const fetchMessages = async () => {
      const url = isGroup ? `/api/groups/${user.id}/messages/` : `/api/messages/${user.id}/?user=${currentUser}`;
      const response = await axios.get(url);
      console.log('Fetched messages from API:', response.data);
      setMessages(response.data);
    };
    fetchMessages();
  }, [user.id, currentUser, isGroup]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString('en-US', options);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const renderMessagesWithDates = (messages) => {
    let lastDate = '';
    return messages.map((message, index) => {
      const messageDate = new Date(message.timestamp).toDateString();
      const showDate = messageDate !== lastDate;
      lastDate = messageDate;
      return (
        <React.Fragment key={index}>
          {showDate && <DateDivider>{formatDate(message.timestamp)}</DateDivider>}
          <MessageWrapper style={{ justifyContent: message.sender.username === currentUser ? 'flex-end' : 'flex-start' }}>
            {message.sender.username === currentUser ? (
              <MessageItemSender>
                <DeleteIconTray>
                  <DeleteIcon onClick={() => deleteMessage(message.id)} />
                </DeleteIconTray>
                {renderMessageContent(message)}
                <TimestampInMessage>{formatTime(message.timestamp)}</TimestampInMessage>
              </MessageItemSender>
            ) : (
              <MessageItemReceiver>
                <strong>{isGroup && `${message.sender.username}: `}</strong>
                {renderMessageContent(message)}
                <TimestampInMessage>{formatTime(message.timestamp)}</TimestampInMessage>
              </MessageItemReceiver>
            )}
          </MessageWrapper>
        </React.Fragment>
      );
    });
  };

  const renderMessageContent = (message) => {
    if (message.file) {
      const fileExtension = message.file.split('.').pop().toLowerCase();
      const fileIcons = {
        pdf: "/static/file-icons/pdf.png",
        doc: "/static/file-icons/file.png",
        docx: "/static/file-icons/file.png",
        xls: "/static/file-icons/xls.png",
        xlsx: "/static/file-icons/xls.png",
        ppt: "/static/file-icons/txt.png",
        pptx: "/static/file-icons/txt.png",
        txt: "/static/file-icons/txt.png",
        csv: "/static/file-icons/txt.png",
        zip: "/static/file-icons/zip.png",
        rar: "/static/file-icons/zip.png",
        default: "/static/file-icons/txt.png"
      };

      const icon = fileIcons[fileExtension] || fileIcons.default;
      const fileName = message.file.split('/').pop();
      
      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        return (
          <ImageContainer>
            <ImageThumbnail src={message.file} onClick={() => setPopupImage(message.file)} />
          </ImageContainer>
        );
      } else {
        return (
          <div style={{cursor: 'pointer'}} onClick={() => downloadFile(fileName)}>
            <img src={icon} alt={`${fileExtension} icon`} style={{ width: '20px', marginRight: '5px' }} />
            {fileName}
          </div>
        );
      }
    } else {
      return <pre style={{ fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginTop: 'auto', marginBottom: 'auto' }}>{message.content}</pre>;
    }
  };

  const fetchGroupMembers = async () => {
    const response = await axios.get(`/api/groups/${user.id}/`);
    setGroupMembers(response.data.members_data);
  };

  const handleAddMember = async (member) => {
    await axios.post(`/api/groups/${user.id}/add_member/`, { user_id: member.id });
    fetchGroupMembers();
  };

  const handleRemoveMember = async (member) => {
    await axios.post(`/api/groups/${user.id}/remove_member/`, { user_id: member.id });
    fetchGroupMembers();
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      const response = await axios.get(`/api/users/search/?q=${term}`);
      setSearchResults(response.data);
    } else {
      setSearchResults([]);
    }
  };
  console.log(isGroup)
  console.log(user.name)
  return (
    <ChatContainer>
      <ChatHeader>
        <Avatar bgColor={getRandomColor(isGroup ? user.name : user.username)}>
          {(isGroup ? user.name : user.username).charAt(0).toUpperCase()}{(isGroup ? user.name : user.username).charAt(1).toUpperCase()}
        </Avatar>
        <UserName>{isGroup ? user.name : user.username}</UserName>
        {isGroup && <GroupManageButton onClick={() => { fetchGroupMembers(); setShowGroupMembers(true); }}>Manage Members</GroupManageButton>}
      </ChatHeader>
      <MessageContainer>
        {renderMessagesWithDates(messages)}
        <div ref={messagesEndRef} />
      </MessageContainer>
      <InputContainer>
        <TextArea
          ref={textAreaRef}
          value={newMessage}
          onChange={handleInput}
          onKeyDown={handleKeyPress}
          placeholder="Type a message"
          rows="1"
          maxRows="4"
        />
        <AttachmentIcon onClick={() => fileInputRef.current.click()} />
        <FileInput
          ref={fileInputRef}
          type="file"
          onChange={handleFileSelect}
        />
        <Button onClick={sendMessage}>Send</Button>
      </InputContainer>
      {popupImage && (
        <ImagePopupOverlay onClick={() => setPopupImage(null)}>
          <ImagePopup src={popupImage} />
          <ImageCloseButton onClick={() => setPopupImage(null)}>X</ImageCloseButton>
          <DownloadButton onClick={() => downloadFile(popupImage.split('/').pop())}>Download</DownloadButton>
        </ImagePopupOverlay>
      )}
      {showGroupMembers && (
        <GroupModalOverlay>
          <GroupModalContent>
            <GroupModalHeader>
              <h2>Group Members</h2>
              <GroupModalCloseButton onClick={() => setShowGroupMembers(false)}>X</GroupModalCloseButton>
            </GroupModalHeader>
            <GroupModalBody>
              <h3>Current Members</h3>
              <UserListContainer>
                {groupMembers.map((member) => (
                  <UserItem key={member.id}>
                    {member.username}
                    <RemoveMemberButton onClick={() => handleRemoveMember(member)}>Remove</RemoveMemberButton>
                  </UserItem>
                ))}
              </UserListContainer>
              <h3>Add Members</h3>
              <input type="text" value={searchTerm} onChange={handleSearch} placeholder="Search users..." />
              <UserListContainer>
                {searchResults.map((user) => (
                  <UserItem key={user.id} onClick={() => handleAddMember(user)}>
                    {user.username}
                  </UserItem>
                ))}
              </UserListContainer>
            </GroupModalBody>
            <GroupModalFooter>
              <Button onClick={() => setShowGroupMembers(false)}>Done</Button>
            </GroupModalFooter>
          </GroupModalContent>
        </GroupModalOverlay>
      )}
    </ChatContainer>
  );
};

export default ChatWindow;
